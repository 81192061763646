import { useContext } from 'react';
import styles from './menu.module.scss';
import classNames from 'classnames';
import { SearchContext } from '../../context/SearchContext';

export interface MenuProps {
    className?: string;
}

const sortBy: { q: string; text: string }[] = [
    { q: 'popularity.desc', text: 'Popularity' },
    { q: 'revenue.desc', text: 'Revenue' },
    { q: 'primary_release_date.desc', text: 'Release date' },
    { q: 'vote_average.desc', text: 'Vote average' },
    { q: 'vote_count.desc', text: 'Vote count' },
];
const genres: { id: string; name: string }[] = [
    {
        id: '28',
        name: 'Action',
    },
    {
        id: '12',
        name: 'Adventure',
    },
    {
        id: '16',
        name: 'Animation',
    },
    {
        id: '35',
        name: 'Comedy',
    },
    {
        id: '80',
        name: 'Crime',
    },
    {
        id: '99',
        name: 'Documentary',
    },
    {
        id: '18',
        name: 'Drama',
    },
    {
        id: '10751',
        name: 'Family',
    },
    {
        id: '14',
        name: 'Fantasy',
    },
    {
        id: '36',
        name: 'History',
    },
    {
        id: '27',
        name: 'Horror',
    },
    {
        id: '10402',
        name: 'Music',
    },
    {
        id: '9648',
        name: 'Mystery',
    },
    {
        id: '10749',
        name: 'Romance',
    },
    {
        id: '878',
        name: 'Science Fiction',
    },
    {
        id: '10770',
        name: 'TV Movie',
    },
    {
        id: '53',
        name: 'Thriller',
    },
    {
        id: '10752',
        name: 'War',
    },
    {
        id: '37',
        name: 'Western',
    },
];

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/configuration-for-menus-and-templates
 */
export const Menu = ({ className }: MenuProps) => {
    const { dispatch, state } = useContext(SearchContext);
    return (
        <div className={classNames(styles.root, className)}>
            <div className={styles.logo}>
                <img
                    src={'https://s2.coinmarketcap.com/static/img/coins/200x200/22629.png'}
                    className={styles.logoImg}
                    alt=""
                />
                <span className={styles.logoText}>Movie Site</span>
            </div>
            <span className={styles.title}>Sort by</span>
            <hr className={styles.hr} />
            <ul className={styles.list}>
                {sortBy.map((item) => (
                    <li
                        className={styles.listItem}
                        key={item.q}
                        onClick={() => dispatch({ type: 'SORT_BY', payload: item.q })}
                    >
                        {item.text}
                    </li>
                ))}
            </ul>
            <span className={styles.title}>Genre</span>
            <hr className={styles.hr} />
            <ul className={styles.list}>
                {genres.map((item) => (
                    <li
                        className={styles.listItem}
                        key={item.id}
                        onClick={() => dispatch({ type: 'ADD_GENRE', payload: item.id })}
                    >
                        {item.name}
                    </li>
                ))}
            </ul>
        </div>
    );
};
