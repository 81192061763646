import styles from './favorite.module.scss';
import classNames from 'classnames';
import { useContext, useEffect } from 'react';
import { FavoriteContext } from '../../context/FavoriteContext';
import { FavoriteCard } from '../favorite-card/favorite-card';

export interface FavoriteProps {
    className?: string;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/configuration-for-favorites-and-templates
 */
export const Favorite = ({ className }: FavoriteProps) => {
    const { state } = useContext(FavoriteContext);
    const { favorites } = state;

    useEffect(() => {
        console.log(favorites);
    }, [favorites]);

    return (
        <div className={classNames(styles.root, className)}>
            <h1>Favorite</h1>
            <h1>{state.favorites.length}</h1>

            {state.favorites.length > 0 &&
                state.favorites.map((movie) => <FavoriteCard movie={movie} key={movie.id} />)}
        </div>
    );
};
